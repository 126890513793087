<template>
  <search-form :items="items" :form="search" @fnSearch="handleFnSearch" />
  <grid
    ref="gridRef"
    :columns="columns"
    :code="$route.path"
    :url="url"
    :search="search"
    :btn-operation="btnOperation"
    :scroll="{ y: gridHeight }"
  >
    <template #enable="{ record }">
      <a-tag :color="record ? 'green' : 'orange'">
        {{ record ? '有效' : '无效' }}
      </a-tag>
    </template>
    <template #operation="{ record }">
      <operation :options="options" :record="record" />
    </template>
  </grid>
  <a-modal
    v-model:visible="visible"
    :title="form.id ? '编辑' : '新增'"
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
  >
    <a-form
      ref="formRef"
      :model="form"
      :rules="rules"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 15 }"
      :validate-trigger="['change', 'blur']"
    >
      <div v-if="!form.id">
        <a-form-item name="actionCode" label="行为编号">
          <a-input
            v-model:value="form.actionCode"
            placeholder="请输入行为编号"
          />
        </a-form-item>
        <a-form-item name="actionType" label="行为类别">
          <a-input
            v-model:value="form.actionType"
            placeholder="请输入行为类别"
          />
        </a-form-item>
      </div>
      <a-form-item name="score" label="行为积分">
        <a-input-number
          v-model:value="form.score"
          :min="0"
          placeholder="请输入行为积分"
        />
      </a-form-item>
      <div v-if="!form.id">
        <a-form-item name="enable" label="状态">
          <a-switch
            v-model:checked="form.enable"
            checked-children="有效"
            un-checked-children="无效"
          />
        </a-form-item>
        <a-form-item name="actionDesc" label="行为描述">
          <a-textarea
            v-model:value="form.actionDesc"
            placeholder="请输入行为描述"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import { Tag, Form, InputNumber, Switch, message } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import integralApi from '@/api/integral';

export default {
  components: {
    ATag: Tag,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
    AInputNumber: InputNumber,
    SearchForm,
    Grid,
    Operation,
  },
  data() {
    const required = { required: true, message: '不能为空' };
    return {
      items: [
        {
          label: '行为积分',
          type: 'range',
          start: 'startScore',
          end: 'endScore',
        },
        {
          key: 'enable',
          label: '状态',
          type: 'radio',
          dataset: [
            { label: '有效', value: 1 },
            { label: '无效', value: 0 },
            { label: '全部', value: undefined },
          ],
        },
      ],
      columns: [
        { dataIndex: 'actionCode', title: '行为编号' },
        { dataIndex: 'actionDesc', title: '行为描述' },
        { dataIndex: 'actionType', title: '行为类别', width: 100 },
        { dataIndex: 'updateTime', title: '更新时间', width: 190 },
        {
          dataIndex: 'enable',
          title: '状态',
          width: 100,
          slots: { customRender: 'enable' },
        },
        { dataIndex: 'score', title: '行为积分', width: 100 },
        {
          key: 'id',
          title: '操作',
          width: 50,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        { type: 'add', permission: 'integral:add', fnClick: this.handleEdit },
      ],
      options: [
        {
          type: 'update',
          permission: 'integral:update',
          fnClick: this.handleEdit,
        },
      ],
      url: integralApi.pageUrl,
      gridHeight: document.body.clientHeight - 384,
      search: {},
      visible: false,
      form: {},
      rules: {
        actionCode: [required],
        actionDesc: [required],
        actionType: [required],
        score: [required],
      },
      enableOptions: [
        { label: '有效', value: '1' },
        { label: '无效', value: '0' },
      ],
      confirmLoading: false,
    };
  },
  methods: {
    handleFnSearch(value) {
      this.search = value;
    },
    handleEdit(item) {
      this.visible = true;
      this.form = item.id
        ? { ...item, enable: item.enable === '1' }
        : {
            id: '',
            actionCode: '',
            actionDesc: '',
            actionType: '',
            enable: true,
            score: 0,
          };
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        let type = 'add';
        this.form.id && (type = 'update');
        this.confirmLoading = true;
        integralApi[type](`integral:${type}`, {
          ...this.form,
          enable: this.form.enable ? '1' : '0',
        })
          .then(() => {
            message.success('操作成功');
            this.visible = false;
            this.$refs.gridRef.refreshGrid();
          })
          .finally(() => (this.confirmLoading = false));
      });
    },
  },
};
</script>

<style scoped></style>
